import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import upload from '../../utils/ajaxUpload';
export default {
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ""
    },
    listType: {
      type: String,
      default: "picture"
    },
    storeType: {
      type: String,
      default: localStorage.getItem("defaultStoreType")
    },
    accept: {
      type: String,
      default: "image/*"
    },
    maxSize: {
      type: Number,
      default: 5
    },
    maxNumber: {
      type: Number,
      default: 5
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    submitUpload: function submitUpload() {
      this.$refs.upload.submit();
    },
    customUpload: function customUpload(options) {
      var suffix = "";

      if (options.file.name.lastIndexOf('.') !== -1) {
        suffix = options.file.name.substring(options.file.name.lastIndexOf('.'));
      }

      var key = this.prefix + "/" + (!this.$common.isEmpty(this.$store.state.currentUser.username) ? this.$store.state.currentUser.username.replace(/[^a-zA-Z]/g, '') + this.$store.state.currentUser.id : this.$store.state.currentAdmin.username.replace(/[^a-zA-Z]/g, '') + this.$store.state.currentAdmin.id) + new Date().getTime() + Math.floor(Math.random() * 1000) + suffix;

      if (this.storeType === "local") {
        var fd = new FormData();
        fd.append("file", options.file);
        fd.append("key", key);
        fd.append("relativePath", key);
        fd.append("type", this.prefix);
        fd.append("storeType", this.storeType);
        return this.$http.upload(this.$constant.baseURL + "/resource/upload", fd, this.isAdmin);
      } else if (this.storeType === "qiniu") {
        var xhr = new XMLHttpRequest();
        xhr.open('get', this.$constant.baseURL + "/qiniu/getUpToken?key=" + key, false);

        if (this.isAdmin) {
          xhr.setRequestHeader("Authorization", localStorage.getItem("adminToken"));
        } else {
          xhr.setRequestHeader("Authorization", localStorage.getItem("userToken"));
        }

        try {
          xhr.send();
          var res = JSON.parse(xhr.responseText);

          if (res !== null && res.hasOwnProperty("code") && res.code === 200) {
            options.data = {
              token: res.data,
              key: key
            };
            return upload(options);
          } else if (res !== null && res.hasOwnProperty("code") && res.code !== 200) {
            return Promise.reject(res.message);
          } else {
            return Promise.reject("服务异常！");
          }
        } catch (e) {
          return Promise.reject(e.message);
        }
      }
    },
    // 文件上传成功时的钩子
    handleSuccess: function handleSuccess(response, file, fileList) {
      var url;

      if (this.storeType === "local") {
        url = response.data;
      } else if (this.storeType === "qiniu") {
        url = this.$constant.qiniuDownload + response.key;
        this.$common.saveResource(this, this.prefix, url, file.size, file.raw.type, "qiniu", this.isAdmin);
      }

      this.$emit("addPicture", url);
    },
    handleError: function handleError(err, file, fileList) {
      this.$message({
        message: err,
        type: "error"
      });
    },
    // 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传
    beforeUpload: function beforeUpload(file) {},
    // 文件列表移除文件时的钩子
    handleRemove: function handleRemove(file, fileList) {},
    // 添加文件、上传成功和上传失败时都会被调用
    handleChange: function handleChange(file, fileList) {
      var flag = false;

      if (file.size > this.maxSize * 1024 * 1024) {
        this.$message({
          message: "图片最大为" + this.maxSize + "M！",
          type: "warning"
        });
        flag = true;
      }

      if (flag) {
        fileList.splice(fileList.size - 1, 1);
      }
    }
  }
};